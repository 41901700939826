import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
} from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"


import LuteinComplexMobile from "./../images/technology-mobile.jpg"
import LuteinComplexTablet from "./../images/technology-tablet.jpg"
import LuteinComplexDesktop from "./../images/technology-desktop.jpg"
import LuteinComplexXL from "./../images/technology-xl.jpg"
import Feature1 from "./../images/flower-liquid-caps.jpg"
import Feature2 from "./../images/LICAPS-GRAPH.svg"
import Feature3 from "./../images/production.svg"
import Feature4 from "./../images/rila-purity.jpg"
import Feature5 from "./../images/safety.svg"
import Feature6 from "./../images/sealing.svg"


const TechnologyPage = () => (
  <Layout backButton={true} pageInfo={{ pageName: "Technology" }}>
    <Seo title="LICAPS&trade; - капсули от ново поколение" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <Breadcrumb.Item active>ТЕХНОЛОГИЯ</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Licaps"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h1 className="display-4 font-light">
            Технология
            <br />
            от ново
            <br />
            поколение
          </h1>
        </Col>
      </Row>

      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Технология от
            <br /> ново поколение
          </h1>
        </Col>
        <Col sm={12} className="mb-4">
          <h5 className="subhead font-light">
            Продуктите ни Лутеин Комплекс Плюс, Хепатофелин и Ставиен, се
            произвеждат във Франция от CAPSUGEL FRANCE SAS&trade; по
            най-съвременната технология за производство на твърди желатинови
            капсули с течно съдържание - LICAPS&trade;.
          </h5>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Капсули с течно съдържание?</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={{ span: 8, offset: 2 }} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Капсули с течно съдържание"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col sm={12} className="order-md-0">
          <p>
            При LICAPS&trade; технологията се елиминира термичната обработка на
            активното вещество, което позволява да се запазят полезните му
            свойства и гарантира бързото му и пълно усвояване от организма. В
            сравнение с твърдите форми като таблетките, съставките намиращи се в
            LICAPS&trade; се освобождават много бързо, защото те са
            предварително разтворени.
          </p>
          <p>
            Технологията е разработена и патентована от CAPSUGEL FRANCE
            SAS&trade;, които произвеждат нашите продукти според GMP (Good
            Manufacturing Practices) – Добрите производствени практики, което
            гарантира качеството на всеки от продуктите ни.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            По-добра ефективност и по-бърза разтворимост
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col sm={12} md={{ span: 10, offset: 1 }} className="order-md-1">
          <img
            srcSet={Feature2}
            alt="По-добра ефективност и по-бърза разтворимост"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col sm={12} className="order-md-0">
          <p>
            Научни изследвания доказват, че течните формули предлагат подобрено
            усвояване.*
          </p>
          <p>
            LICAPS&trade; са капсули с течно съдържание изработени по
            патентованата технология на CAPSUGEL FRANCE SAS&trade; за влагане на
            течни растителни екстракти и други течни форми в твърди капсули. При
            този процес се елиминира термичната обработка на съставките. Това
            позволява да се запазят полезните им свойства в максимална степен и
            гарантира бързото им и пълно усвояване.
          </p>
          <p>
            В сравнение с таблетите и другите подобни методи на на доставка, при
            които веществото е твърда форма, ингредиентите в LICAPS&trade; се
            освобождават много бързо, тъй като са предварително разтворени.
            Самите капсули се разтварят напълно след приемане.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Производство</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={5} className="order-sm-1">
          <img
            srcSet={Feature3}
            alt="Производство"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={7} className="order-sm-0">
          <p>
            LICAPS&trade; използва патентована технология LEMS за запечатване на
            течности в капсулите. Постига се сигурно запечатване, с което се
            избягва изтичане на течното съдържание и се предпазват съставките от
            окисляване и овлажняване.
          </p>
          <p>
            Капсулите съдържат азотно мехурче, вследствие запечатването в азотна
            среда, което гарантира запазване на свежестта на продукта. Азотното
            мехурче повишава стабилността на съдържанието и значително намалява
            образуването на Пероксиди и реактивен кислород.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Чистота и натуралност на капсулите и съдържанието
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Гаранция за качество и сигурност"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Капсулите LICAPS&trade; защитават затворените в тях съставки от
            кислород и външни въздействия, като не позволяват окисляване,
            влошаване на качествата и странични взаимодействия на съставките.
          </p>
          <p>
            От друга страна капсулите не могат да бъдат отваряни и отново
            затваряни, след като запечатването вече е било нарушено. В сравнение
            с таблетките, LICAPS&trade; съдържат много по-малко допълнителни
            вещества. LICAPS&trade; капсулите са съобразени, както с всички
            изисквания за натурален и чист продукт, също така и с културни и
            религиозни предпочитания като вегетарианство, Халал, Кашер и други.
            LICAPS&trade; нямат мирис и вкус и не дразнят стомашно-чревния
            тракт.
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <ul>
            <li>Без разтворители</li>
            <li>Без консерванти</li>
            <li>Без глутен</li>
            <li>Без нишесте</li>
            <li>Без захар</li>
          </ul>
        </Col>
        <Col md={6}>
          <ul>
            <li>По-малко помощни вещества</li>
            <li>Без пластификатори</li>
            <li>Без алергени</li>
            <li>Без ГМО</li>
          </ul>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Безопасност и гаранция за качество</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={5} className="order-md-1">
          <img
            srcSet={Feature5}
            alt="Гаранция за качество и сигурност"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={7} className="order-md-0">
          <p>
            LICAPS&trade; защитават веществата в тях от кислород и външно
            влияние, като по този начин намаляват оксидация, деградация,
            хидролизация и странични реакции на веществата. От друга страна,
            веднъж отворена LICAPS&trade; капсула, по никакъв начин не може да
            бъде затворена, което гарантира защитен, чист и безопасен продукт.
          </p>
          <p>
            <a href="https://vitaherb.bg/leaflets/Lutein-Complex-Plus-Quality-Certificate.pdf">
              Сертификат за качество на Лутеин Комплекс
            </a>
            <br />
            <a href="https://vitaherb.bg/leaflets/Hepatofelin-Quality-Certificate.pdf">
              Сертификат за качество на Хепатофелин
            </a>
            <br />
            <a href="https://vitaherb.bg/leaflets/Stavien-Quality-Certificate.pdf">
              Сертификат за качество на Ставиен
            </a>
          </p>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">LEMS технология за запечатване</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature6}
            alt="Гаранция за качество и сигурност"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Ексклузивната за CAPSUGEL&trade; технология LEMS (Liquid
            Encapsulation Microspray Sealing), предоставя сигурно запечатване на
            капсулите и намалява възможността за потичане на съдържанието извън
            капсулата.
          </p>
          <p>
            LICAPS&trade; са затворени по начин, който създава бариера срещу
            кислород и вода, предпазваща съдържанието на капсулите. Капсулите са
            запечатани в азотна среда, което избягва оксидацията на
            съдържанието, запазва свежестта на продуктите и увеличава срока на
            годност.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Изследвания и източници</h5>
        </Col>
      </Row>
      <Row className="mb-4 product-container">
        <Col className="h6-small">
          <p>
            * N. Madit*, D. Cade and H. Benameur, Comparative bioavailability of
            CoQ 10 formulations: Licaps™ formulation versus current commercial
            formulations. Development Center, Research and Development. Capsugel
            FRANCE SAS, 10 rue Timken Colmar F68027 (2006)
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default TechnologyPage
